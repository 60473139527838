body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.ZY-logo{
  width:1200px;
  margin:0 auto;
  /*background:rgba(2, 167, 240, 1);*/
  overflow:hidden;
  padding:1%;
  height: 100%;
}
.ZY-logo .layoutList{
    display: flex;
    align-items: center;
    color: white;
}
.navigation-button{
  width: 24%;
  font-size: 23px;
  text-align: center;
  color: rgba(2, 167, 240, 0.56078431372549);

}
.selected{
  color: rgba(7, 59, 171, 0.56);
  background: white;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  border-radius: 30px;
}
.img-button{
  height: 5em;
  width: 24%;
  padding-left: 1%;
  margin-bottom: 10px;
  object-fit: contain;
}
.ZY-check{
    text-align: center;
    font-size: 18px;
    color: white;
}
.ZY-check:hover{
    color: rgba(7, 59, 171, 0.56);
    font-weight: 600;
    background: white;
    text-align: center;
    font-size: 18px;
    border-radius: 30px;
}
.SuccessImage{
    width: 49%;
    object-fit: contain;
    margin-bottom: 10px;
    display: block;
}
